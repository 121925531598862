import Grid from 'Atoms/Grids/Grid';
import { GridSize } from 'Enums/GridSize.enum';
import StandardPageModel from 'Models/Pages/StandardPage/StandardPageModel.interface';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import ContentArea from 'Organisms/ContentArea/ContentArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

function StandardPage() {
  const { heroHeader, heroImage, contentArea, inEditMode } =
    useCurrentPage<StandardPageModel>();

  return (
    <>
      {heroImage && (
        <SmallHeroBlock
          header={heroHeader}
          inEditMode={inEditMode}
          image={heroImage}
          overlay={false}
        />
      )}
      <Grid
        noPadding={false}
        data-content-area
        type={GridSize.Twelve}
        {...applyEditModeAttr(inEditMode && 'MainContent')}
      >
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </Grid>
    </>
  );
}

export default StandardPage;
